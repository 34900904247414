























import { Component, Prop, Vue } from 'vue-property-decorator';
import { getFlipstarters } from '@/flipstarter';
import CampaignJumbotron from '@/components/CampaignJumbotron.vue';
import Intro from '@/components/Intro.vue';
import Loading from '@/components/Loading.vue';

Vue.filter('status', (status: string) => {
  //
  const uppercaseFirst = status.charAt(0).toUpperCase() + status.slice(1);
  return `Status: ${uppercaseFirst}`;
});

@Component({
  components: {
    CampaignJumbotron,
    Intro,
    Loading,
  },
})

export default class ActiveFlipstarters extends Vue {
  @Prop() private msg!: string;

  private loadingFlipstarters = true;

  private error = '';

  // eslint-disable-next-line
  private flipstarters: any = null;

  pagination = {
    perPage: 10,
    currentPage: 1,
    running: [],
  }

  rows = this.pagination.running.length;

  created() {
    getFlipstarters().then((data) => {
      this.flipstarters = data;
      this.pagination.running = this.flipstarters.filter((f: { status: string }) => f.status === 'running');
    }).catch((error) => {
      this.error = error.message;
    }).then(() => {
      this.loadingFlipstarters = false;
    });
  }

  get pRunning() {
    return this.pagination.running.slice().reverse().slice((this.pagination.currentPage - 1)
      * this.pagination.perPage, this.pagination.currentPage * this.pagination.perPage);
  }
}

<template>
  <div class="Active">
    <ActiveFlipstarters />
  </div>
</template>

<script>
// @ is an alias to /src
import ActiveFlipstarters from '@/components/ActiveFlipstarters.vue';

export default {
  name: 'Active',
  components: {
    ActiveFlipstarters,
  },
};
</script>
